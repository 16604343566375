export default {
    namespaced: true,
    state: {
        invest_nominal: null,
        invest_unit: null,
        switch_all_units: "N"
    },

    mutations: {
        investNominal: (state, payload) => {
            state.invest_nominal = payload;
        },
        investUnit: (state, payload) => {
            state.invest_unit = payload;
        },
        switchAllUnits: (state, payload) => {
            state.switch_all_units = payload;
        }        
    },

    actions: {
        updateInvestNominal: ({ commit }, payload) => {
            commit('investNominal', payload)
        },
        updateInvestUnit: ({ commit }, payload) => {
            commit('investUnit', payload)
        },
        updateSwitchAllUnits: ({ commit }, payload) => {
            commit('switchAllUnits', payload)
        },        
    },

    getters: {
        invest_nominal: state => state.invest_nominal,
        invest_unit: state => state.invest_unit,
        switch_all_units: state => state.switch_all_units,
    }
}
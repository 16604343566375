// import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueCryptojs from 'vue-cryptojs';
import VueSignaturePad from 'vue-signature-pad';
import VueClipboard from 'vue-clipboard2';
import moment from 'moment';
import 'moment/locale/id';
import OtpInput from "@bachdgvn/vue-otp-input";
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css';
import 'vuesax/dist/vuesax.css'


Vue.prototype.moment = moment;
Vue.component("v-otp-input", OtpInput);
Vue.use(VueSignaturePad);
Vue.use(VueClipboard)
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(DatePicker);
Vue.use(Vuesax, {
 theme:{
   colors:{
     primary:'#3F0975',
     secondary:'#EE503F',
     tertiary:'#FFCE51',
     success:'rgb(23, 201, 100)',
     danger:'rgb(242, 19, 93)',
     warning:'rgb(255, 130, 0)',
     dark:'rgb(36, 33, 69)'
   }
 }
})
Vue.mixin({
 methods: {
   formatPrice(value) {
      let val = Math.ceil(value).toFixed(0).replace('.',',');
       // let val = (value/1).toFixed(2).replace('.', ',')
       return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
   },
   formatUsd(value) {
      let val = Math.ceil(value).toFixed(2).replace(',','.');
       // let val = (value/1).toFixed(2).replace('.', ',')
       return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
   },
   formatNab(value) {
       let val = (value/1).toFixed(3).replace('.', ',')
       return val.toString().replace(/\B(?=(\d{3})+(?!\d))/, ".")
   },
   replaceChar(value) {
       let val = value;
       return val.replace(/-/g, '');
   }   
 }
})
Vue.config.productionTip = false;


// axios.defaults.baseURL = `${process.env.VUE_APP_ROOT_API}/api/`
axios.defaults.baseURL = `${process.env.VUE_APP_ROOT_API}/api/`


var numeral = require('numeral');

Vue.filter("formatCurrency", function (value) {
 return numeral(value).format("0,0");
});


Vue.filter("formatDecimal", function (value) {
 return numeral(value).format("0,0.0000");
});




Vue.filter("formatPercent", function (value) {
 return numeral(value).format("0.0[0]");
});




store.dispatch('auth/me').finally(() => {
 new Vue({
   router,
   store,
   VueCryptojs,
   // @ts-ignore
   render: h => h(App)
 }).$mount('#app')

})


/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from "axios";
import { encryptData } from "@/utils";

const instance = axios.create({
  withCredentials: true,
});

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: [],
    ifua_number: "",
    access_token: "",
    isUserSyariah: false,
    s21_client: [],
  },

  getters: {
    check(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    ifua_number: (state) => state.ifua_number,
    access_token: (state) => state.access_token,
    isUserSyariah: (state) => state.isUserSyariah,
    s21_client: (state) => state.s21_client,
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_IFUA(state, value) {
      state.ifua_number = value;
    },
    SET_ACCESS_TOKEN(state, value) {
      state.access_token = value;
    },
    SET_IS_USER_SYARIAH(state, value) {
      state.isUserSyariah = value;
    },
    SET_CLIENT(state, value) {
      state.s21_client = value;
    },
  },
  actions: {
    async login({ commit, dispatch }, credentials) {
      await instance.get(
        `${process.env.VUE_APP_ROOT_API}/sanctum/csrf-cookie`,
        { withCredentials: true }
      );
      const data_login = await instance.post(
        `${process.env.VUE_APP_ROOT_API}/api/auth/login`,
        credentials
      );

      commit("SET_ACCESS_TOKEN", data_login.data.data.access_token);
      return dispatch("me");
    },

    async me({ commit }) {
      try {
        let { data } = await instance.get(
          `${process.env.VUE_APP_ROOT_API}/api/auth/user`
        );
        // let { data } = await axios.get(`${process.env.VUE_APP_ROOT_API}/api/2321`,{
        //   headers: {
        //     Authorization: 'Bearer 50486|VWauh7mnS9uVnOTqSxTYDrjUxK7Y6aVveS9k4cuu'
        //   }
        // })
        let { data: data2 } = await instance.get(
          `${process.env.VUE_APP_ROOT_API}/api/s21client?clientId=${encryptData(
            data?.data?.individual?.client_id
          )}`
        );
        commit("SET_AUTHENTICATED", true);
        commit("SET_USER", data.data);
        commit("SET_IFUA", data.data);
        commit("SET_CLIENT", data2.data);
        commit(
          "SET_IS_USER_SYARIAH",
          data2?.data?.ClientCategory?.toLowerCase() === "eq-sy"
        );
        return data.data;
      } catch (e) {
        console.log(e);
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", []);
        commit("SET_IFUA", []);
      }
    },

    async signout({ dispatch }) {
      await instance.post(`${process.env.VUE_APP_ROOT_API}/logout`);
      localStorage.removeItem("block_transaction_fbonds");

      dispatch("me");
    },

    async setIsUserSyariah({ commit }, data) {
      commit("SET_IS_USER_SYARIAH", data);
    },
  },
};

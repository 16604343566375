export default {
    namespaced: true,
    state: {
        invest_nominal: 0,
        invest_unit: 0,
        sell_all_unit: "N"
    },

    mutations: {
        investNominal: (state, payload) => {
            state.invest_nominal = payload;
        },
        investUnit: (state, payload) => {
            state.invest_unit = payload;
        },
        sellAllUnit: (state, payload) => {
            state.sell_all_unit = payload;
        }
    },

    actions: {
        updateInvestNominal: ({ commit }, payload) => {
            commit('investNominal', payload)
        },
        updateInvestUnit: ({ commit }, payload) => {
            commit('investUnit', payload)
        },
        updateSellAllUnit: ({ commit }, payload) => {
            commit('sellAllUnit', payload)
        },
    },

    getters: {
        invest_nominal: state => state.invest_nominal,
        invest_unit: state => state.invest_unit,
        sell_all_unit: state => state.sell_all_unit,
    }
}
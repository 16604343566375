export default {
    namespaced: true,
    state: {
        invest_nominal: null,
    },

    mutations: {
        investNominal: (state, payload) => {
            state.invest_nominal = payload;
        }
    },

    actions: {
        updateInvestNominal: ({ commit }, payload) => {
            commit('investNominal', payload)
        }
    },

    getters: {
        invest_nominal: state => state.invest_nominal,
    }
}
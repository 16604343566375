import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import subscription from './subscription'
import switching from './switching'
import redeem from './redeem'
import idlemoney from './idlemoney'

Vue.config.devtools = true
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bearer_token: "Bearer 7c4a8d09ca3762af61e59520943dc26494f8941b",
    app_url: `${process.env.VUE_APP_ROOT_API}/api/`, 
    image_api_url: `${process.env.VUE_APP_ROOT_API}/`, 
    image_core: `${process.env.VUE_APP_ROOT_CORE}/`, 

  },

  mutations: {

  },
  actions: {

  },
  modules: {
    auth,
    subscription,
    switching,
    redeem,
    idlemoney,
  },
  getters: {
    bearer_token: state => state.bearer_token,
    app_url: state => state.app_url,
    image_api_url: state => state.image_api_url,
    image_core: state => state.image_core,
    // dname: state => state.dname,
  }
})



import axios from 'axios'

const instance = axios.create({
  withCredentials: true
})

export default {
  namespaced: true,
  state: {
    isFromLogin: false,
  },
  actions: {
    changeIsFromLogin(state, value) {
      state.commit("CHANGE_ISLOGIN_VALUE", value);
    },

    async submitIdleMoney(context,credentials) {
      try {
        localStorage.setItem("s2321IdleMoneyStatus", credentials.is_idle_money);
        return await instance.post(`${process.env.VUE_APP_ROOT_API}/api/individual-account/update-idle-money`, credentials)
      } catch (error) {
        console.log(error);
      }
    },

    async getProdIdleMoney() {
      try {
        return await instance.get(`${process.env.VUE_APP_ROOT_API}/api/product-idle-money`)
      } catch (error) {
        console.log(error);
      }
    },

  },
  mutations: {
    CHANGE_ISLOGIN_VALUE(state, value) {
      state.isFromLogin = value;
    },

  },
  getters: {
    getIsLogin(state) {
      return state.isFromLogin;
    },
  }
};
